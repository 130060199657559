/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Veritcal Tabs
        (function(){
          var text = $('.vtabs .vertical'),
              tabWidth = $('.vtabs .nav-tabs li').width(),
              tabsHeight = $('.vtabs .nav-tabs').height(),
              content = $('.vtabs .tab-content');

          text.height(tabWidth);
          content.css('min-height', tabsHeight);
        })();

        // Featured Sponsors
        $('.featured-sponsors').slick({
            dots: false,
            arrows: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
            speed: 500,
            fade: true,
            cssEase: 'linear'
        });

        // Featured Sponsors
        $('.square-ads').slick({
            dots: false,
            arrows: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3500,
            speed: 500,
            fade: true,
            cssEase: 'linear'
        });

        // Accordions
        var allPanels = $('.block-accordion > .block-accordion__row > .block-accordion__row-content').hide();
        $('.block-accordion > .block-accordion__row > .block-accordion__row-title').click(function() {

            if ($(this).hasClass('accordion--open')) {
                $(this).next().slideUp();
                $(this).removeClass('accordion--open');
            } else {
                allPanels.slideUp();
                $(this).next().slideDown();
                $(this).addClass('accordion--open');
            }
            return false;
        });

        // Bio Modals
        $('.bios__bio').click(function(e) {
          e.preventDefault();
          $(this).next('.modal').modal('show');
        });

        // Sidr
        $('#mobile-nav').sidr({
          source: '.search-wrapper, .nav-primary, .social-wrapper',
          side: 'right'
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        if (matchMedia('only screen and (min-width: 992px)').matches) {
          console.log('MD size');
          $('.eq-column').matchHeight({
            byRow: true,
            property: 'height',
            target: null,
            remove: false
          });
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Homepage Slider
        $('.home-slides').slick({
            dots: true,
            arrows: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 6000,
            speed: 500,
            fade: true,
            cssEase: 'linear'
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Upcoming events page, note the change from upcoming-events-us to upcoming_events.
    'upcoming_events': {
      init: function() {

        function emptyEventMonth(filterLabel) {
          var months = $('.event-list__month');
          $.each(months, function(index, value) {
            console.log(index);
            console.log($(value).children('.row:visible'));
            if ($(value).children('.row:visible').length === 0) {
              $(value).append('<div class="row event-list__month-empty"><p>No "'+filterLabel+'" events found.</p></div>');
            } else {
              $(value).children('.event-list__month-empty').remove();
            }

          });
        }

        function getEventSliderSettings() {
          return {
            dots: false,
            arrows: true,
            prevArrow: $('.event-list__page-prev'),
            nextArrow: $('.event-list__page-next'),
            infinite: false,
            autoplay: false,
            speed: 500,
            fade: true,
            adaptiveHeight: true,
            cssEase: 'linear'
          };
        }
        // JavaScript to be fired on the upcoming events page
        $('.event-list__events').slick(getEventSliderSettings());

        $(document).on('click', '.do-filter', function(e) {
          e.preventDefault();
          var filter = $(e.target).data('filter');
          var events = $('.event-list__event');
          var currentSlide = $('.event-list__events').slick('slickCurrentSlide');
          var filterText = $(e.target).text();
          if (filter === 'all') {
            events.fadeIn().promise().done(function() {
              emptyEventMonth(filterText);
              $('.event-list__events').slick('unslick');
              $('.event-list__events').slick(getEventSliderSettings());
              $('.event-list__events').slick('slickGoTo', currentSlide);
            });
          } else {
            events.fadeOut().promise().done(function() {
              events.filter('.'+filter).fadeIn().promise().done(function() {
                emptyEventMonth(filterText);
                $('.event-list__events').slick('unslick');
                $('.event-list__events').slick(getEventSliderSettings());
                $('.event-list__events').slick('slickGoTo', currentSlide);
              });
            });
          }
          // console.log(events.filter('.'+filter));
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
